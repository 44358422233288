import React from "react"
import { graphql } from "gatsby"
import ContentPageComponent from "../components/content-page.component"
import { linkFragment } from "../link-resolver"

const ContentPage = ({ data }) => {
  const page = data.prismic.contentPage
  if (page) {
    return <ContentPageComponent pageContext={page} />
  }
  return null
}

ContentPage.fragments = [linkFragment]

export default ContentPage

export const query = graphql`
  query contentPageQuery($uid: String!, $lang: String!) {
    prismic {
      contentPage(uid: $uid, lang: $lang) {
        page_title
        description
        keywords {
          keyword
        }
        preview_image
        _meta {
          uid
          lang
          type
        }
        header_slides {
          content
          icon
          label
          background_image
        }
        aside_navigation {
          ... on PRISMIC_AsideNavigation {
            text
            body {
              ... on PRISMIC_AsideNavigationBodyMenu {
                fields {
                  icon
                  link {
                    ...link
                  }
                  link_style
                  link_text
                }
                label
                primary {
                  nav_link {
                    ...link
                  }
                  nav_text
                }
                type
              }
            }
          }
        }
        parent {
          ... on PRISMIC_ContentPage {
            _meta {
              type
              uid
            }
            parent {
              ... on PRISMIC_ContentPage {
                _meta {
                  type
                  uid
                }
              }
            }
          }
        }
        body {
            ...on PRISMIC_ContentPageBodyShipping_calculator {
                type
                label
                primary {
                    bg_color
                    bg_image
                    title
                    text
                    calculator_title
                    calculate_button_calculate
                    calculator_button_request
                    calculator_contact_email
                    calculator_contact_first_name
                    calculator_contact_last_name
                    calculator_contact_telephone
                    calculator_contact_title
                    calculator_destination_container_1
                    calculator_destination_container_2
                    calculator_destination_country
                    calculator_destination_port
                    calculator_destination_title
                    calculator_location_select
                    calculator_location_title
                    calculator_title
                    calculator_total_cost
                    calculator_tub_itinerary
                    calculator_tub_rate_quote
                }
            }
          ...on PRISMIC_ContentPageBodyBlog_posts {
            type
            label
            primary {
              bg_color
              bg_image
              title
              text
              featured
              number_of_posts
              
              category {
                ... on PRISMIC_BlogCategory {
                  _meta {
                    lang
                    type
                    uid
                  }
                }
              }
            }
          }
          ...on PRISMIC_ContentPageBodyVideo {
            type
            label
            primary {
              bg_color
              bg_image
              title
              text
            }
            fields {
              video_thumbnail
              video_url
            }
          }
          ... on PRISMIC_ContentPageBodyTestimonials {
                type
                label
                primary {
                    text
                    title
                    bg_color
                    bg_image
                }
                fields {
                    client_name
                    client_photo
                    image
                    link {
                        ...link
                    }
                    link_text
                    link_text
                    rating
                    text
                    video_url
                }
            }
          ... on PRISMIC_ContentPageBodyPricing_plans {
            type
            label
            fields {
              price_units
              plan_price
              plan_name
              plan_features
              link_text
              link_style
              link {
                ...link
              }
              is_free_plan
            }
            primary {
              bg_image
              bg_color
              title
              text
            }
          }
          ... on PRISMIC_ContentPageBodyItems_collection {
            label
            type
            primary {
              bg_color
              bg_image
              text
              title
              link_style
              link_text
            }
            fields {
              tag
            }
          }
          ... on PRISMIC_ContentPageBodyForm {
            type
            label
            primary {
              bg_color
              bg_image
              form_script
              form_url
              text
            }
          }
          ... on PRISMIC_ContentPageBodyFeatute {
            type
            label
            primary {
              bg_image
              bg_color
              text
              title
            }
            fields {
              image
              link_style
              link_text
              text
              title
              link {
                ...link
              }
            }
          }
          ... on PRISMIC_ContentPageBodyBlock_with_text_and_image {
            type
            label
            primary {
              bg_color
              bg_image
              min_height
              image
              link_style
              link_text
              link {
                ...link
              }
              text
              title
            }
          }
          ... on PRISMIC_ContentPageBodyText {
            type
            label
            primary {
              text
              background_color
              background_image
            }
          }
          ... on PRISMIC_ContentPageBodyText_with_title {
            type
            label
            fields {
              text
            }
            primary {
              background_color
              background_image
              title
              link_text
              text
              link {
                ...link
              }
            }
          }
          ... on PRISMIC_ContentPageBodyImage_with_text {
            type
            label
            primary {
              background_color
              background_image
              text
            }
            fields {
              image
              text
            }
          }
          ... on PRISMIC_ContentPageBodyTwo_columns {
            type
            label
            fields {
              border_color
              html
              text
            }
            primary {
              background_color
              background_image
              text
            }
          }
          ... on PRISMIC_ContentPageBodyMap {
            type
            label
            primary {
              latitude
              longitude
            }
          }
          ... on PRISMIC_ContentPageBodyGallery {
            type
            label
            fields {
              image
            }
            primary {
              bg_color
              bg_image
              text
            }
          }
          ... on PRISMIC_ContentPageBodyText_with_title_list {
            type
            label
            fields {
              text
              title
            }
            primary {
              text
            }
          }
          ... on PRISMIC_ContentPageBodyContacts {
            type
            label
            primary {
              bg_color
              bg_image
              text
              title
            }
            fields {
              address
              description
              email
              fax
              phone
            }
          }
          ... on PRISMIC_ContentPageBodyApplication_title {
              type
              label
              primary {
                  title
                  image
                  apply_button_text
                  phone
              }
          }
          ... on PRISMIC_ContentPageBodyApply_form {
              type
              label
              primary {
                  main_text
                  left_column
                  form_script
              }
          }
          ... on PRISMIC_ContentPageBodyJob_description_short {
              type
              label
              primary {
                  title
                  image
                  job_description
              }
              fields {
                  button_text
                  button_link {
                      ...link
                  }
              }
          }  
        }
      }
    }
  }
`
